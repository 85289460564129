import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  count: 0,
  info: {},
};

const DatasetSlice = createSlice({
  name: "dataset",
  initialState: initialState,
  reducers: {
    update: (state, action) => {
      console.log("[DatasetReducer] update");
      return {
        ...state,
        info: action.payload,
      };
    },
    updateCount: (state, action) => {
      return {
        ...state,
        count: action.payload,
      };
    },
    clear: (state) => {
      return initialState;
    },
  },
});

export const { clear, update, updateCount } = DatasetSlice.actions;

export default DatasetSlice.reducer;
