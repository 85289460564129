import CFG from "../config";

import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  split,
} from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { setContext } from "@apollo/client/link/context";
import { gql } from "@apollo/client";

import AuthService from "./AuthService";

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URL,
});

const authLink = setContext((_, { headers }) => {
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: AuthService.isLoggedIn()
        ? `Bearer ${AuthService.getToken()}`
        : "",
    },
  };
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: process.env.REACT_APP_GRAPHQL_SUBSCRIPTION_URL,
    connectionParams: {
      authorization: AuthService.isLoggedIn()
        ? `Bearer ${AuthService.getToken()}`
        : "",
    },
    timeout: 5000,
    options: {
      reconnect: true,
    },
  })
);

// The split function takes three parameters:
//
// * A function that's called for each operation to execute
// * The Link to use for an operation if the function returns a "truthy" value
// * The Link to use for an operation if the function returns a "falsy" value
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  authLink.concat(httpLink)
);

const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache(),
  connectToDevTools: process.env.REACT_APP_ENVIRONMENT === "dev" ? true : false,
  defaultOptions: {
    query: {
      fetchPolicy: "network-only",
    },
  },
});

const configure = () => {};

const toTaskEnum = (task_name) => {
  switch (task_name) {
    case CFG.TASK_ID.CV.IMAGE_CLASSIFICATION.ID:
      return "IMG_CLS";
    case CFG.TASK_ID.CV.OBJECT_DETECTION.ID:
      return "OBJ_DET";
    default:
      throw new Error(`Unknown task name: ${task_name}`);
  }
};

const toTaskID = (task_name) => {
  switch (task_name) {
    case "IMG_CLS":
      return CFG.TASK_ID.CV.IMAGE_CLASSIFICATION.ID;
    case "OBJ_DET":
      return CFG.TASK_ID.CV.OBJECT_DETECTION.ID;
    default:
      throw new Error(`Unknown task name: ${task_name}`);
  }
};

const toTaskName = (task_name) => {
  switch (task_name) {
    case "IMG_CLS":
      return CFG.TASK_ID.CV.IMAGE_CLASSIFICATION.NAME;
    case "OBJ_DET":
      return CFG.TASK_ID.CV.OBJECT_DETECTION.NAME;
    default:
      throw new Error(`Unknown task name: ${task_name}`);
  }
};

const toReadableMetric = (metric) => {
  switch (metric) {
    case "M_AP":
      return "mAP";
    case "ACCU":
      return "% (Acc.)";
    default:
      throw new Error(`Unknown metric: ${metric}`);
  }
};

const toReadableProgressStatus = (status) => {
  switch (status) {
    case "CREATED":
    case "QUEUED":
    case "PROCESSING":
    case "UPLOADING":
      return CFG.RESULT.PROCESSING;
    case "COMPLETED":
    case "NOT_CREATED":
      return CFG.RESULT.SUCCESS;
    case "FAILED":
      // FIXME: 'error' does not match to CFG.RESULT.FAILED
      return "error";
    default:
      throw new Error(`Unknown status: ${status}`);
  }
};

const toOneOfReadableProgressStatus = (statusArray) => {
  const readableStatusArray = statusArray.map((status) =>
    toReadableProgressStatus(status)
  );
  if (readableStatusArray.includes("error")) {
    // FIXME: 'error' does not match to CFG.RESULT.FAILED
    return "error";
  }
  if (readableStatusArray.includes("processing")) {
    return CFG.RESULT.PROCESSING;
  }
  return CFG.RESULT.SUCCESS;
};

const toDatasetFormatEnum = (task_name) => {
  switch (task_name) {
    case CFG.TASK_ID.CV.IMAGE_CLASSIFICATION.ID:
      return "IMG_CLS_ROBOFLOW_FOLDER_TREE";
    case CFG.TASK_ID.CV.OBJECT_DETECTION.ID:
      return "OBJ_DET_ROBOFLOW_COCO";
    default:
      throw new Error(`Unknown task name: ${task_name}`);
  }
};

const GraphQLService = {
  configure,
  client,
};

const uploadFile = async (fileUploadID, fileForm) => {
  const createFileUploadSession = await GraphQLService.client.mutate({
    mutation: gql`
      mutation createFileUploadSession(
        $fileUploadID: ID!
        $filename: String!
        $fileSize: Int!
        $mimeType: String!
      ) {
        createFileUploadSession(
          id: $fileUploadID
          input: { filename: $filename, mimeType: $mimeType, size: $fileSize }
        ) {
          __typename
          ... on NoPermissionError {
            debug
          }
          ... on InputFileTooBigError {
            argument
            debug
          }
          ... on InputFileUnsupportedError {
            debug
          }
          ... on InputError {
            argument
            debug
          }
          ... on FileUploadSession {
            id
            url
            method
            mimeType
            fileUpload {
              id
              type
              status
              __typename
            }
          }
        }
      }
    `,
    variables: {
      fileUploadID: fileUploadID,
      filename: fileForm.name,
      fileSize: fileForm.size,
      mimeType: fileForm.type,
    },
  });
  const {
    id: fileUploadSessionID,
    url,
    method,
    mimeType,
    fileUpload,
  } = createFileUploadSession.data.createFileUploadSession;

  console.log(createFileUploadSession);

  const requestOptions = {
    method: method, // Most likely 'PUT'
    headers: { "Content-Type": mimeType },
    body: fileForm,
  };
  const response = await fetch(url, requestOptions);
  const etag = response.headers.get("ETag").replace(/["]+/g, "");

  console.log(response);

  const completFileUploadSession = await GraphQLService.client.mutate({
    mutation: gql`
      mutation completFileUploadSession(
        $fileUploadID: ID!
        $fileUploadSeesionID: String!
        $fileUploadETag: String!
      ) {
        completeFileUploadSession(
          fileUploadSeesionID: $fileUploadSeesionID
          fileUploadID: $fileUploadID
          input: { eTag: $fileUploadETag }
        ) {
          __typename
          ... on NoPermissionError {
            debug
          }
          ... on FileUpload {
            id
            type
            status
            __typename
          }
        }
      }
    `,
    variables: {
      fileUploadID: fileUploadID,
      fileUploadSeesionID: fileUploadSessionID,
      fileUploadETag: etag,
    },
  });
};

export default GraphQLService;

export {
  toTaskEnum,
  toTaskID,
  toTaskName,
  toReadableMetric,
  toReadableProgressStatus,
  toOneOfReadableProgressStatus,
  toDatasetFormatEnum,
  uploadFile,
};
