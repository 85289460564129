import Keycloak from "keycloak-js";

import { useState } from "react";

const _kc = new Keycloak({
  url: process.env.REACT_APP_KEYCLOAK_URL,
  realm: process.env.REACT_APP_KEYCLOAK_REALM,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
});

let _intervalID;

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback, kcContext) => {
  if (kcContext === undefined) {
    _kc
      .init({
        responseMode: "fragment",
        flow: "standard",
        pkceMethod: "S256",
        onLoad: "login-required",
      })
      .then((authenticated) => {
        if (authenticated) {
          // Check token validity every 10 seconds (10 000 ms) and, if necessary, update the token.
          // Refresh token if it's valid for less then 60 seconds
          _intervalID = setInterval(() => {
            // console.log("Checking token validity...")
            _kc
              .updateToken(60)
              .then((refreshed) => {
                if (refreshed) {
                  // console.log('Token was successfully refreshed');
                } else {
                  // console.log("Token is still valid.")
                }
              })
              .catch(() => {
                console.log("Token expired. Logging out...");
                _kc.clearToken();
                // TODO: Notify react app to call AuthService.doLogout()
              });
          }, 10000);

          // Then render DOM
          onAuthenticatedCallback();
        } else {
          doLogin();
        }
      })
      .catch(console.error);
  } else {
    onAuthenticatedCallback();
  }
};

const doLogin = _kc.login;

const doLogout = () => {
  clearInterval(_intervalID);
  _kc.logout();
};

const getToken = () => _kc.token;

const isLoggedIn = () => !!_kc.token;

const updateToken = (successCallback) =>
  _kc.updateToken(60).then(successCallback).catch(doLogin);

const getUsername = () => _kc.idTokenParsed?.email;

const getGroupID = () => Object.keys(_kc.tokenParsed?.organizations)[0];

const getGroupDisplayName = (id) =>
  _kc.tokenParsed?.organizations_attributes[id]["attributes"][
    "display_name"
  ][0];

const getGroupPicture = (id) =>
  _kc.tokenParsed?.organizations_attributes[id]["attributes"]["picture"][0];

const AuthService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  getGroupID,
  getGroupDisplayName,
  getGroupPicture,
};

export default AuthService;
