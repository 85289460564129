import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  count: 0,
  refreshList: false,
  info: {},
  plots: {},
};

const ModelSlice = createSlice({
  name: "model",
  initialState: initialState,
  reducers: {
    update: (state, action) => {
      return {
        ...state,
        info: action.payload,
      };
    },
    updatePlots: (state, action) => {
      return {
        ...state,
        plots: {
          ...state.plots,
          [action.payload.modelID]: action.payload.modelPlot,
        },
      };
    },
    updateRefresh: (state, action) => {
      return {
        ...state,
        refreshList: action.payload,
      };
    },
    count: (state, action) => {
      return {
        ...state,
        count: action.payload,
      };
    },
    clear: (state) => {
      return initialState;
    },
  },
});

export const { update, updatePlots, updateRefresh, count, clear } =
  ModelSlice.actions;

export default ModelSlice.reducer;
