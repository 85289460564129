import "./styles/globals.css";
import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { getKcContext } from "keycloakify";

import store from "./store";
import AuthService from "./services/AuthService";
import GraphQLService from "./services/GraphQLService";

const App = lazy(() => import("./App"));
const KcApp = lazy(() => import("./KcApp"));
const { kcContext } = getKcContext(
  process.env.REACT_APP_ENVIRONMENT === "keycloack_dev"
    ? {
        mockPageId: "login.ftl",
      }
    : {}
);

const renderApp = () =>
  ReactDOM.render(
    <Suspense fallback={<div></div>}>
      {kcContext === undefined ? (
        <Provider store={store}>
          <App />
        </Provider>
      ) : (
        <KcApp kcContext={kcContext} />
      )}
    </Suspense>,
    document.getElementById("root")
  );

AuthService.initKeycloak(renderApp, kcContext);
GraphQLService.configure();
