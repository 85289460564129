import { configureStore } from "@reduxjs/toolkit";
import DatasetReducer from "./reducers/DatasetReducer";
import ModelReducer from "./reducers/ModelReducer";
import ModelSearchReducer from "./reducers/ModelSearchReducer";
// import ModelCompressionReducer from "./reducers/ModelCompressionReducer"
import ModelZooReducer from "./reducers/ModelZooReducer";

const store = configureStore({
  reducer: {
    dataset: DatasetReducer,
    model: ModelReducer,
    modelSearch: ModelSearchReducer,
    // modelCompression: ModelCompressionReducer,
    modelZoo: ModelZooReducer,
  },
});
export default store;
