import { DotChartOutlined, LineChartOutlined } from "@ant-design/icons";

const CFG = {
  COMMON: {
    SESSION_CHECK_INTERVAL: 5000,
    GENERAL_CONTENT_HEIGHT: 750,
    GENERAL_CONTENT_SCROLL_HEIGHT: 685,
    MODEL_ZOO_TSNE_HEIGHT: 650,
    MODEL_ZOO_METRIC_HEIGHT: 180,
    MODEL_FINETUNE_CHART_HEIGHT: 250,
    PAGE_SIZE: 100,
  },
  TASK_ID: {
    ALL: "0",
    CV: {
      OBJECT_DETECTION: {
        ID: "12",
        NAME: "Object Detection 2D",
      },
      IMAGE_CLASSIFICATION: {
        ID: "11",
        NAME: "Image Classification",
      },
      // OBJECT_DETECTION_3D: {
      //     ID: '13',
      //     NAME: "Object Segmentation 3D"
      // },
      // INTANCE_SEGMENTATION: {
      //     ID: '13',
      //     NAME: "Instance Segmentation"
      // },
    },
    NLP: {},
    TABULAR: {},
  },
  DASHBOARD: {
    URL: {
      GROUP_INFO: "",
      ACTIVITY_IFNO: "",
      RESOURCE_INFO: "",
    },
    VIEW: {
      MAIN_VIEW: {
        DASHBOARD_TABS: {
          GROUP: {
            key: "1",
          },
          ACCOUNT: {
            key: "2",
          },
        },
        HISTORY_TABS: {
          MODEL_SEARCH: {
            key: "1",
            tab: "Model Search",
          },
          MODEL_COMP: {
            key: "2",
            tab: "Model Compression",
          },
          MODELS: {
            key: "3",
            tab: "My Models",
          },
          DATASETS: {
            key: "4",
            tab: "My Datasets",
          },
        },
      },
      ACTIVITY_VIEW: {
        CHART_HEIGHT: 160,
      },
      RESOURCE_VIEW: {
        CHART_HEIGHT: 310,
      },
    },
  },
  MODEL_SEARCH: {
    URL: {
      HISTORY: "",
      MODEL_ZOO: {
        USE_CACHE: true,
        IMG_CLS:
          "https://media.automl.dev.deepauto.ai/static/model_zoo/img_cls_model_zoo.json",
        OD: "https://media.automl.dev.deepauto.ai/static/model_zoo/obj_det_model_zoo.json",
      },
    },
    RANGE: {
      IMAGE_CLASSIFICATION: {
        NUM_PARAMS: {
          RANGE: [0.1, 500],
          UNIT: "M",
        },
        FLOPS: {
          RANGE: [1, 1200],
          UNIT: "M",
        },
        LATENCY: {
          RANGE: [1, 30],
          UNIT: "ms",
        },
        TARGET_PERF: {
          RANGE: [0, 100],
          UNIT: "Accurcy (%)",
        },
      },
      OBJECT_DETECTION: {
        NUM_PARAMS: {
          RANGE: [0.1, 500],
          UNIT: "M",
        },
        FLOPS: {
          RANGE: [0.1, 1000],
          UNIT: "G",
        },
        LATENCY: {
          RANGE: [0, 100],
          UNIT: "ms",
        },
        TARGET_PERF: {
          RANGE: [0.01, 1.0],
          UNIT: "mAP",
        },
      },
    },
    VIEW: {
      MAIN_VIEW: {
        TABS: {
          PROJECT: {
            key: "1",
            tab: "My Projects",
          },
          MODEL_ZOO: {
            key: "2",
            tab: "Model Zoo",
          },
        },
      },
      MODEL_ZOO_VIEW: {
        TABS: {
          OVERVIEW: {
            key: "1",
            tab: "Overview",
          },
          // FINETUNE: {
          //     key: '2',
          //     tab: 'Finetune'
          // }
        },
        VIZ_TYPE: {
          TSNE: {
            value: "1",
            label: "t-SNE",
            icon: <DotChartOutlined />,
          },
          METRIC: {
            value: "2",
            label: "Metric",
            icon: <LineChartOutlined />,
          },
        },
        AXIS_TYPE: {
          PARAMS: {
            value: "params",
            name: "Pameter Size",
          },
          FLOPS: {
            value: "flops",
            name: "FLOPs",
          },
        },
        TSNE_HEIGHT: 800,
        MODEL_ZOO_TASK_MAPPING: {
          "6000-store-items-images-classified-by-color_imoore_0_12": 0,
          DL2020_0_4: 1,
          "ads5035-01_0_2": 2,
          ai2020f_0_11: 3,
          "aia-dl-mid_0_4": 4,
          "apparel-images-dataset_trolukovich_0_12": 5,
          "apparel-images-dataset_trolukovich_12_24": 6,
          "asl-alphabet_grassknoted_0_15": 7,
          "asl-alphabet_grassknoted_15_29": 8,
          "best-artworks-of-all-time_ikarus777_0_17": 9,
          "best-artworks-of-all-time_ikarus777_17_34": 10,
          "best-artworks-of-all-time_ikarus777_34_51": 11,
          "blood-cells_paultimothymooney_0_4": 12,
          breakhis_ambarish_0_8: 13,
          "breast-histopathology-images_paultimothymooney_0_2": 14,
          "cactus-aerial-photos_irvingvasquez_0_2": 15,
          "car-classificationproject-vision_0_15": 16,
          "car-classificationproject-vision_15_30": 17,
          "car-classificationproject-vision_30_45": 18,
          "cassava-leaf-disease-classification_0_5": 19,
          "celeba-dataset_jessicali9530_0_2": 20,
          "chessman-image-dataset_niteshfre_0_6": 21,
          "classification-of-handwritten-letters_olgabelitskaya_0_17": 22,
          "classification-of-handwritten-letters_olgabelitskaya_17_33": 23,
          "computed-tomography-ct-images_vbookshelf_0_2": 24,
          corales_0_14: 25,
          "crack-identification-ce784a-2020-iitk_0_2": 26,
          "cs4487-2020fall_0_2": 27,
          cs4670spring2020pa3_0_16: 28,
          "csep546-aut19-kc2_0_5": 29,
          "cv2020-classification-challenge_0_20": 30,
          "cv2020-classification-challenge_100_120": 31,
          "cv2020-classification-challenge_20_40": 32,
          "cv2020-classification-challenge_40_60": 33,
          "cv2020-classification-challenge_60_80": 34,
          "cv2020-classification-challenge_80_100": 35,
          "day-3-kaggle-competition_0_5": 36,
          "defi1-ia_0_3": 37,
          "devanagari-character-set_rishianand_0_16": 38,
          "devanagari-character-set_rishianand_16_31": 39,
          "devanagari-character-set_rishianand_31_46": 40,
          dlai3_0_2: 41,
          "e4040fall2019-assignment-2-task-5_0_5": 42,
          "fcis-sc-deeplearning-competition_0_10": 43,
          "flowers-recognition_alxmamaev_0_5": 44,
          "four-shapes_smeschke_0_4": 45,
          "fruit-recognition_chrisfilo_0_15": 46,
          fruits_moltean_0_19: 47,
          fruits_moltean_113_131: 48,
          fruits_moltean_19_38: 49,
          fruits_moltean_38_57: 50,
          fruits_moltean_57_76: 51,
          fruits_moltean_76_95: 52,
          fruits_moltean_95_113: 53,
          "garbage-classification_asdasdasasdas_0_6": 54,
          "gen-2-ai-force-challenge-1_0_10": 55,
          "gpa759-2020_0_17": 56,
          "gpa759-2020_17_34": 57,
          "gpa759-2020_34_50": 58,
          "gtsrb-german-traffic-sign_meowmeowmeowmeowmeow_0_15": 59,
          "gtsrb-german-traffic-sign_meowmeowmeowmeowmeow_15_29": 60,
          "gtsrb-german-traffic-sign_meowmeowmeowmeowmeow_29_43": 61,
          "hackathon-blossom-flower-classification_spaics_0_17": 62,
          "hackathon-blossom-flower-classification_spaics_17_34": 63,
          "hackathon-blossom-flower-classification_spaics_34_51": 64,
          "hackathon-blossom-flower-classification_spaics_51_68": 65,
          "hackathon-blossom-flower-classification_spaics_68_85": 66,
          "hackathon-blossom-flower-classification_spaics_85_102": 67,
          "image-classification_duttadebadri_0_4": 68,
          "intel-image-classification_puneet6060_0_6": 69,
          "khu-deep-learning-competition_0_10": 70,
          kunstmatigeintelligentie20192020_0_5: 71,
          "labeled-surgical-tools_dilavado_0_4": 72,
          "land-cover-class_0_10": 73,
          "lego-brick-images_joosthazelzet_0_16": 74,
          "lego-brick-sorting-image-recognition_pacogarciam3_0_20": 75,
          "lego-minifigures-classification_ihelon_0_14": 76,
          "lego-vs-generic-brick-image-recognition_pacogarciam3_0_4": 77,
          "make-up-vs-no-make-up_petersunga_0_2": 78,
          "malefemale-for-drr_0_2": 79,
          "messy-vs-clean-room_cdawn1_0_2": 80,
          "microsoft-catsvsdogs-dataset_shaunthesheep_0_2": 81,
          "mis583-hw2-part-2_0_5": 82,
          mllabgame_0_10: 83,
          "mushrooms-classification-common-genuss-images_maysee_0_9": 84,
          mvtec_anomaly_detection_carpet_0_6: 85,
          mvtec_anomaly_detection_grid_0_6: 86,
          mvtec_anomaly_detection_leather_0_6: 87,
          "nnfl-cnn-lab2_0_6": 88,
          notmnist_jwjohnson314_0_10: 89,
          numta_BengaliAI_0_10: 90,
          "nuu-me-midterm-exam-image-classification_0_5": 91,
          "oregon-wildlife_virtualdvid_0_20": 92,
          "parkinsons-drawings_kmader_0_2": 93,
          perritos_0_10: 94,
          "plant-seedlings-classification_0_12": 95,
          "proptit-aif-homework-1_0_8": 96,
          "real-and-fake-face-detection_ciplab_0_2": 97,
          "real-life-industrial-dataset-of-casting-product_ravirajsinh45_0_2": 98,
          rockpaperscissors_drgfreeman_0_3: 99,
          "sample_nih-chest-xrays_0_2": 100,
          "sfu-cmpt-computer-vision-course-cnn_0_20": 101,
          "sfu-cmpt-computer-vision-course-cnn_100_120": 102,
          "sfu-cmpt-computer-vision-course-cnn_120_140": 103,
          "sfu-cmpt-computer-vision-course-cnn_140_160": 104,
          "sfu-cmpt-computer-vision-course-cnn_160_180": 105,
          "sfu-cmpt-computer-vision-course-cnn_180_200": 106,
          "sfu-cmpt-computer-vision-course-cnn_20_40": 107,
          "sfu-cmpt-computer-vision-course-cnn_40_60": 108,
          "sfu-cmpt-computer-vision-course-cnn_60_80": 109,
          "sfu-cmpt-computer-vision-course-cnn_80_100": 110,
          "sheep-breed-classification_divyansh22_0_4": 111,
          "simpsons-challenge-gft_0_20": 112,
          "simpsons-challenge-gft_20_39": 113,
          simpsons4_0_20: 114,
          simpsons4_20_39: 115,
          "skin-cancer9-classesisic_nodoubttome_0_9": 116,
          sldc_0_10: 117,
          "stanford-dogs-dataset-traintest_miljan_0_20": 118,
          "stanford-dogs-dataset-traintest_miljan_100_120": 119,
          "stanford-dogs-dataset-traintest_miljan_20_40": 120,
          "stanford-dogs-dataset-traintest_miljan_40_60": 121,
          "stanford-dogs-dataset-traintest_miljan_60_80": 122,
          "stanford-dogs-dataset-traintest_miljan_80_100": 123,
          "stanford-dogs-dataset_jessicali9530_0_20": 124,
          "stanford-dogs-dataset_jessicali9530_100_120": 125,
          "stanford-dogs-dataset_jessicali9530_20_40": 126,
          "stanford-dogs-dataset_jessicali9530_40_60": 127,
          "stanford-dogs-dataset_jessicali9530_60_80": 128,
          "stanford-dogs-dataset_jessicali9530_80_100": 129,
          "synthetic-digits_prasunroy_0_10": 130,
          "tau-ethiopic-digit-recognition_0_10": 131,
          "the-simpsons-characters-dataset_alexattia_0_20": 132,
          "the-simpsons-characters-dataset_alexattia_20_39": 133,
          "tl-signs-hse-itmo-2020-winter_0_17": 134,
          "tl-signs-hse-itmo-2020-winter_17_34": 135,
          "tl-signs-hse-itmo-2020-winter_34_51": 136,
          "tl-signs-hse-itmo-2020-winter_51_67": 137,
          vehicle_0_17: 138,
          "zalando-store-crawl_dqmonn_0_6": 139,
        },
      },
      PROJECT_VIEW: {
        VIZ_TYPE: {
          TSNE: {
            value: "1",
            label: "t-SNE",
            icon: <DotChartOutlined />,
          },
          METRIC: {
            value: "2",
            label: "Metric",
            icon: <LineChartOutlined />,
          },
        },
        TABS: {
          OVERVIEW: {
            key: "1",
            label: "Overview",
          },
          ANALYTICS: {
            key: "2",
            label: "Analytics",
          },
        },
        RESULT_TABS: {
          OVERVIEW: {
            key: "1",
            label: "Vizualization",
          },
          LIST: {
            key: "2",
            label: "Models ",
          },
        },
        DEVICE: [
          {
            value: "CPU",
            label: "CPU",
            children: [
              {
                value: "Intel",
                label: "Intel",
                children: [
                  {
                    value: "i5",
                    label: "i5",
                  },
                  {
                    value: "i7",
                    label: "i7",
                  },
                  {
                    value: "i9",
                    label: "i9",
                  },
                ],
              },
              {
                value: "AMD",
                label: "AMD",
                children: [
                  {
                    value: "Ryzen 3",
                    label: "Ryzen 3",
                  },
                  {
                    value: "Ryzen 5",
                    label: "Ryzen 5",
                  },
                  {
                    value: "Ryzen 7",
                    label: "Ryzen 7",
                  },
                ],
              },
            ],
          },
          {
            value: "GPU",
            label: "GPU",
            children: [
              {
                value: "NVIDIA",
                label: "NVIDIA",
                children: [
                  {
                    value: "Jetson",
                    label: "Jetson",
                    children: [
                      {
                        value: "Nano",
                        label: "Nano",
                      },
                    ],
                  },
                  {
                    value: "GeForce",
                    label: "GeForce",
                    children: [
                      {
                        value: "2080 ti",
                        label: "2080 ti",
                      },
                      {
                        value: "3080 ti",
                        label: "3080 ti",
                      },
                      {
                        value: "4080 ti",
                        label: "4080 ti",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    },
  },
  MODEL_COMPRESSION: {
    URL: {
      HISTORY: "",
    },
    VIEW: {
      MAIN_VIEW: {
        TABS: {
          1: {
            key: "1",
            tab: "SVNet - FasterRCNN",
          },
        },
      },
    },
  },
  MODELS: {
    URL: {
      HISTORY: "",
    },
    VIEW: {
      DETAIL_VIEW: {
        TABS: {
          OVERVIEW: {
            key: "1",
            label: "Overview",
          },
          DEPLOYMENT: {
            key: "2",
            label: "Deployment",
          },
        },
      },
    },
  },
  DATASETS: {
    URL: {
      HISTORY: "",
    },
    VIEW: {
      DETAIL_VIEW: {
        TABS: {
          OVERVIEW: {
            key: "1",
            label: "Overview",
          },
          // CLASS_DETAIL: {
          //     key: '2',
          //     label: 'Class Details'
          // }
        },
        CHART_HEIGHT: 280,
      },
      DATA_TYPE: {
        TRAIN: {
          value: "1",
          label: "Train",
        },
        VALID: {
          value: "2",
          label: "Validation",
        },
        TEST: {
          value: "3",
          label: "Test",
        },
      },
    },
  },

  RESULT: {
    SUCCESS: "success",
    PROCESSING: "processing",
    FAILED: "failed",
    OTHERS: "others",
  },
};
export default CFG;
