import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  info: {},
};

const ModelSearchSlice = createSlice({
  name: "modelSearch",
  initialState: initialState,
  reducers: {
    update: (state, action) => {
      return {
        ...state,
        info: action.payload,
      };
    },
    clear: (state) => {
      return initialState;
    },
  },
});

export const { update, clear } = ModelSearchSlice.actions;

export default ModelSearchSlice.reducer;
