import { createSlice } from "@reduxjs/toolkit";
import CFG from "../config";

const initialState = {
  info: {
    modelZooID: CFG.TASK_ID.CV.OBJECT_DETECTION.ID,
  },
};

const ModelZooSlice = createSlice({
  name: "modelZoo",
  initialState: initialState,
  reducers: {
    update: (state, action) => {
      return {
        ...state,
        info: action.payload,
      };
    },
    updateData: (state, action) => {
      return {
        ...state,
        [action.payload.taskID]: action.payload.data,
      };
    },
    clear: (state) => {
      return initialState;
    },
  },
});

export const { update, updateData, clear } = ModelZooSlice.actions;

export default ModelZooSlice.reducer;
